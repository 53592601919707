import bcrypt from "bcryptjs";
import { useState } from "react";
import { toast } from "react-toastify";

const useSharedDocumentAuthentication = () => {
  const [loading, setLoading] = useState(false);
  const saltRounds = 10;

  /**
   * パスワードを生成する
   */

  const generateRandomPassword = (): string => {
    const passwordLength = 14;
    const charSets = {
      lowerCase: "abcdefghijklmnopqrstuvwxyz",
      upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      number: "0123456789",
      special: "!@#$%^&*()_+[]{};:",
    };
    const allChars = Object.values(charSets).join("");

    // 各セットから1つのキャラクターを保証するために取得
    let result = Object.values(charSets)
      .map((chars) => chars.charAt(Math.floor(Math.random() * chars.length)))
      .join("");

    for (let i = result.length; i < passwordLength; i++) {
      result += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }

    return result;
  };

  /**
   * パスワードをハッシュ化する
   */
  const hashPassword = async (password: string): Promise<string> => {
    setLoading(true);
    try {
      const hash = await bcrypt.hash(password, saltRounds);
      return hash;
    } catch (error) {
      console.error("Error hashing password:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  /**
   * パスワードとハッシュを比較する
   */
  const verifyPassword = async (
    password: string,
    hashedPassword: string,
  ): Promise<boolean> => {
    try {
      const match = await bcrypt.compare(password, hashedPassword);
      return match;
    } catch (error) {
      toast.error("Error verifying password:");
      throw error;
    }
  };

  return {
    generateRandomPassword,
    hashPassword,
    verifyPassword,
    loading,
  };
};

export default useSharedDocumentAuthentication;
