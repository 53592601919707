import React from "react";

import { DropdownField, InitialNameIcon } from "@spesill/components/atoms";

import { DocumentWithUser } from "@spesill/hooks/firestore/documents/useDocumentsWithUser";
import {
  UserPermissionType,
  UserWithPermission,
} from "@spesill/models/permittedUser";

import { UserPermissionDropdownField } from "./SharedUserDropDownFiled";

type PropsType = {
  permittedUsers: UserWithPermission[];
  document: DocumentWithUser;
  className?: string;
  disabled?: boolean;
  onChangePermissions: (userId: string, permission: UserPermissionType) => void;
};

export const SharedSpecificUserList = ({
  permittedUsers,
  document,
  disabled = true,
  className = "",
  onChangePermissions,
}: PropsType) => {
  return (
    <div className={`flex flex-col ${className}`}>
      {/* ドキュメント作成者を表示 */}
      {document.user && (
        <div className="flex items-center space-x-4">
          <InitialNameIcon
            className="flex-shrink-0"
            name={document.user.lastName}
          />
          <div className="flex-grow">
            <p className="font-bold">{document.user.fullName}</p>
            <p className="text-blueGray-500">{document.user.email}</p>
          </div>
          <div className="w-38">
            <DropdownField
              value={{ value: "creator", label: "作成者" }}
              disabled
              name={"permission"}
              options={[]}
              onChange={() => 0}
            />
          </div>
        </div>
      )}
      {/* 許可されたユーザーを表示 */}
      {permittedUsers.map((user) => (
        <div className="flex items-center space-x-4" key={user.id}>
          <InitialNameIcon
            className="flex-shrink-0"
            name={user.lastName || user.firstName || "招待中のユーザー"}
          />
          <div className="flex-grow">
            <p className="font-bold">
              {user.lastName
                ? `${user.lastName + user.firstName}`
                : "招待中のユーザー"}
            </p>
            <p className="text-blueGray-500">{user.email}</p>
          </div>
          <div className="w-38">
            <UserPermissionDropdownField
              user={user}
              disabled={disabled}
              onChangePermissions={onChangePermissions}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
