import React, { FormEvent, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  FormSubmitButton,
  Label,
  DatePicker,
} from "@spesill/components/atoms";
import {
  LabelWithTextField,
  ModalWrapper,
} from "@spesill/components/molecules";

import { useBoolean, useCurrentUser } from "@spesill/hooks";
import useSharedDocumentAuthentication from "@spesill/hooks/firestore/sharedDocuments/useSharedDocumentAuthentication";
import { Document } from "@spesill/models";
import { SharedDocumentRepository } from "@spesill/repository/db/sharedDocument.repository";

type PropsType = {
  onClose: () => void;
  refetch: () => void;
  document: Document;
  className?: string;
};

const initialDate = new Date();
initialDate.setDate(initialDate.getDate() + 14);

export const PublicLinkEnableModal = ({
  onClose,
  refetch,
  document,
  className = "",
}: PropsType) => {
  const { currentUser } = useCurrentUser();
  const { generateRandomPassword, hashPassword } =
    useSharedDocumentAuthentication();
  const [password] = useState(generateRandomPassword());
  const [expireDate, setExpireDate] = useState<Date>(initialDate);
  const { isChecked: isLoading, setTrue, setFalse } = useBoolean(false);
  const handleCreateShareLink = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTrue();
    const sharedDocumentRepository = new SharedDocumentRepository();
    if (!currentUser) return;
    const hashedPassword = await hashPassword(password);

    try {
      await sharedDocumentRepository.add({
        documentId: document.id,
        tenantId: document.tenantId,
        publisherId: currentUser?.id,
        password: hashedPassword,
        expireDate: expireDate,
      });
      toast.success("共有設定を更新しました");
      refetch();
      onClose();
    } catch (_err) {
      toast.error("エラーです");
    } finally {
      setFalse();
    }
  };

  return (
    <ModalWrapper
      onClose={onClose}
      className={`${className}`}
      headerTitle="外部リンクを設定"
    >
      <form
        className="mt-6 space-y-4 flex flex-col justify-between"
        onSubmit={handleCreateShareLink}
      >
        <div>
          <LabelWithTextField
            labelText="アクセスコード"
            type="text"
            name="password"
            required
            placeholder="アクセスコードを入力してください"
            value={password}
            disabled
          />
          <p className="text-sm text-blueGray-400">こちらをメモしてください</p>
        </div>
        <div className="w-full">
          <Label
            htmlFor="expireDate"
            labelText="有効期限"
            className="block"
            required
          />
          <DatePicker
            required
            selected={expireDate}
            onChange={(date: Date) => setExpireDate(date)}
            className="mt-1.5"
          />
        </div>
        <div className="space-x-4 text-right">
          <Button
            text={"キャンセル"}
            color={"gray"}
            variant={"contained"}
            onClick={onClose}
          />
          <FormSubmitButton
            text="有効化"
            color="primary"
            variant="contained"
            className="text-sm"
            disabled={isLoading}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};
