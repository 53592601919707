import React, { useMemo, useState } from "react";

import { Button } from "@spesill/components/atoms";
import { DocumentLayout } from "@spesill/components/layouts";
import {
  DocumentListRow,
  FilterButton,
  FreePlanIndex,
  SortButton,
} from "@spesill/components/organisms";
import { GenerateDocumentButton } from "@spesill/components/organisms/Document/GenerateDocumentButton/GenerateDocumentButton";
import { UploadDocumentFormatModal } from "@spesill/components/organisms/Modal/DocumentFormatModal/UploadDocumentFormatModal";
import { ShareDocumentModal } from "@spesill/components/organisms/Modal/ShareDocumentModal/ShareDocumentModal";

import {
  useBoolean,
  useCurrentUser,
  useDocumentsWithUser,
  useFetchUsers,
} from "@spesill/hooks";
import { Document } from "@spesill/models";

// NOTE: 一括系の実装をする際には、0e8b15d を参考にできると思います。
const DocumentsIndex = () => {
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const { currentPlan } = useCurrentUser();
  const isFreePlan = useMemo(() => currentPlan === "FREE", [currentPlan]);

  const {
    documents,
    selectedFilter,
    sort,
    logicalDeleteDocument,
    selectCreateUserId,
    resetFilterCreateUserId,
    changeSortOrderDirection,
    fetchDocumentsAndUsers: refetch,
  } = useDocumentsWithUser();
  const { users } = useFetchUsers();
  const {
    isChecked: isUploadModalOpen,
    setTrue: setUploadModalOpen,
    setFalse: setUploadModalClose,
  } = useBoolean(false);
  const {
    isChecked: isOpenFilter,
    setTrue: onOpenFilter,
    setFalse: onCloseFilter,
  } = useBoolean(false);
  const {
    isChecked: isOpenShareModal,
    setTrue: onOpenShareModal,
    setFalse: onCloseShareModal,
  } = useBoolean(false);

  const roleItems = useMemo(
    () =>
      users
        .filter((user) => !user.isUnregistered())
        .map((user) => ({
          label: `${user.lastName} ${user.firstName}`,
          selected: user.id === selectedFilter?.createUserId,
          onClick: () => selectCreateUserId(String(user.id)),
        })),
    [selectedFilter, selectCreateUserId, users],
  );

  const menuItems = useMemo(
    () => [
      {
        label: "リセット",
        onClick: resetFilterCreateUserId,
      },
      ...roleItems,
    ],
    [roleItems, resetFilterCreateUserId],
  );

  const handleOpenShareModal = (document: Document) => {
    setSelectedDocument(document);
    onOpenShareModal();
  };

  if (isFreePlan) return <FreePlanIndex documentId={documents[0]?.id} />;

  return (
    <DocumentLayout>
      <div className="w-full space-y-6">
        <div className="flex space-x-2">
          <GenerateDocumentButton />
          <Button
            text="フォーマットファイルを読込"
            color="primary"
            variant="contained"
            onClick={() => setUploadModalOpen()}
          />
        </div>
        <div className="mt-4 space-y-2">
          <div className="flex py-3 px-4 font-semibold">
            <div className="flex w-full items-center">
              <div className="flex-1 flex gap-x-4 items-center">データ名</div>
              <SortButton
                text="更新日時"
                className="w-1/6"
                order={sort.order}
                onChange={changeSortOrderDirection}
              />
              <FilterButton
                text="作成者"
                className="w-1/6"
                menuItems={menuItems}
                isOpen={isOpenFilter}
                onOpen={onOpenFilter}
                onClose={onCloseFilter}
              />
            </div>
            <div className="w-1/12" />
          </div>
          <div className="h-[70vh] overflow-y-auto space-y-2">
            {documents.length < 1 ? (
              <div className="bg-white w-full flex gap-x-4 text-center text-blueGray-700 py-3 px-4 rounded-md">
                該当する文書はありません
              </div>
            ) : (
              <>
                {documents.map((document) => {
                  return (
                    <DocumentListRow
                      key={document.id}
                      document={document}
                      deleteDocument={logicalDeleteDocument}
                      handleOpenShareModal={handleOpenShareModal}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      {isUploadModalOpen && (
        <UploadDocumentFormatModal onClose={setUploadModalClose} />
      )}
      {isOpenShareModal && selectedDocument && !isFreePlan && (
        <ShareDocumentModal
          onClose={onCloseShareModal}
          document={selectedDocument}
          refetch={refetch}
        />
      )}
    </DocumentLayout>
  );
};

export default DocumentsIndex;
