import React from "react";

import { InitialNameIcon, DropdownField } from "@spesill/components/atoms";

import { User } from "@spesill/models";

type PropsType = {
  usersList: User[];
};

export const SharedAllUserList = ({ usersList }: PropsType) => (
  <div className="flex flex-col overflow-y-auto justify-between">
    {usersList.map((user) => (
      <div className="flex items-center space-x-4" key={user.id}>
        <InitialNameIcon
          className="flex-shrink-0"
          name={user.lastName || user.firstName || "招待中のユーザー"}
        />
        <div className="flex-grow">
          <p className="font-bold">
            {user.lastName ? user.fullName : "招待中のユーザー"}
          </p>
          <p className="text-blueGray-500">{user.email}</p>
        </div>
        <div className="w-38">
          <DropdownField
            value={{
              value: "editor",
              label: `${"編集者"}`,
            }}
            required
            disabled={true}
            name={"permission"}
            options={[]}
            onChange={() => 0}
          />
        </div>
      </div>
    ))}
  </div>
);
