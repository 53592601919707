import React from "react";
import { toast } from "react-toastify";

import { Button } from "@spesill/components/atoms";

import { formatDate } from "@spesill/libs/dateFns";
import { SharedDocument } from "@spesill/models";

type PropsType = {
  onOpen: () => void;
  onDeleteSharedDocument: () => void;
  sharedDocument?: SharedDocument;
  className?: string;
};

export const PublicLinkManager = ({
  onOpen,
  onDeleteSharedDocument,
  sharedDocument,
  className = "",
}: PropsType) => {
  // ここで削除とリンクの有無の確認を行う

  const copyToClipboard = () => {
    if (!sharedDocument) {
      toast.error("リンクが存在しません");
      return;
    }
    navigator.clipboard.writeText(
      `${window.location.origin}/shared/verify?id=${sharedDocument.id}`,
    );
    toast.success("リンクをコピーしました");
  };

  return (
    <div className={`${className}`}>
      {sharedDocument ? (
        <>
          <div className="flex items-center justify-center gap-4">
            <Button
              icon={{
                icon: "fiLink2",
                size: "1.5rem",
                color: "text-status-danger",
              }}
              text={"外部リンクを無効化"}
              color={"red"}
              variant={"text"}
              onClick={onDeleteSharedDocument}
            />
            <p className="text-blueGray-300 w-1/2 text-sm leading-5 flex-wrap">
              リンクとアクセスコードを知る人は誰でも閲覧できるようになります。
            </p>
          </div>
          <Button
            onClick={copyToClipboard}
            text={"リンクをコピー"}
            color={"gray"}
            variant={"contained"}
            className="w-full my-6 text-blueGray-900 justify-center"
            icon={{
              icon: "fiLink2",
              size: "1.5rem",
              color: "text-bluGray-900",
            }}
          />
          <p className="text-blueGray-400 text-sm">
            有効期限: {formatDate(sharedDocument.expireDate)}
          </p>
        </>
      ) : (
        <div className="flex items-center justify-center gap-4">
          <Button
            icon={{
              icon: "fiLink2",
              size: "1.5rem",
              color: "text-primary-400",
            }}
            text={"外部リンクを有効化"}
            color={"primary"}
            variant={"text"}
            onClick={onOpen}
          />
          <p className="text-blueGray-300 w-1/2 text-sm leading-5 flex-wrap">
            リンクとアクセスコードを知る人は誰でも閲覧できるようになります。
          </p>
        </div>
      )}
    </div>
  );
};
