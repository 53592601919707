import React, { useMemo } from "react";

import { Icon } from "@spesill/components/atoms";

import { PermittedUser, User } from "@spesill/models";

type PropsType = {
  users: User[];
  permittedUsers: PermittedUser[];
  onChangePermission: (userId: string) => void;
};

export const SelectTenantUserMenuList = ({
  users,
  permittedUsers,
  onChangePermission,
}: PropsType) => {
  const permittedUserIds = useMemo(
    () => permittedUsers.map((userPermission) => userPermission.id),
    [permittedUsers], // userPermissionsの変化を監視して、変更があれば再計算する
  );
  const isPermitted = (userId: string) => {
    return permittedUserIds.includes(userId);
  };
  return (
    <>
      {users.map((user) => {
        return (
          <li
            key={user.id}
            className={`py-2 ${isPermitted(user.id) ? "bg-blueGray-0" : ""}`}
          >
            <button
              type="button"
              className="flex w-full items-center space-x-2 text-sm"
              onClick={() => onChangePermission(user.id)}
            >
              {isPermitted(user.id) && (
                <Icon icon={"mdDone"} size="1rem" color="text-primary-400" />
              )}
              <span
                className={`truncate ${
                  isPermitted(user.id) ? "text-primary-500" : ""
                }`}
              >
                {user.lastName ? user.fullName : user.email}
              </span>
            </button>
          </li>
        );
      })}
    </>
  );
};
