import React, { useCallback } from "react";
import { toast } from "react-toastify";

import {
  Button,
  DropdownField,
  Label,
  TextFieldWithIcon,
} from "@spesill/components/atoms";
import {
  FloatingMenu,
  LabelWithDropdownField,
  ModalWrapper,
} from "@spesill/components/molecules";

import {
  useBoolean,
  useFetchUsers,
  useDocumentPermittedUsers,
  useDocumentPermissionDropdownField,
  useDocumentWithPermission,
} from "@spesill/hooks";
import { DocumentWithUser } from "@spesill/hooks/firestore/documents/useDocumentsWithUser";
import { useSharedDocument } from "@spesill/hooks/firestore/sharedDocuments/useSharedDocument";
import { DocumentPermissionType } from "@spesill/models/document";

import { PublicLinkEnableModal } from "./PublicLinkManager/PublicLinkEnableModal";
import { PublicLinkManager } from "./PublicLinkManager/PublicLinkManager";
import { SelectTenantUserMenuList } from "./SelectTenantUserMenuList";
import { SharedAllUserList } from "./SharedUserList/SharedAllUserList";
import { SharedSpecificUserList } from "./SharedUserList/SharedSpecificUserList";

type PropsType = {
  onClose: () => void;
  document: DocumentWithUser;
  refetch: () => void;
  className?: string;
};
export const ShareDocumentModal = ({
  onClose,
  document,
  refetch,
  className = "",
}: PropsType) => {
  const {
    dropdownValue: dropdownValuePermissionLevel,
    dropdownSelectableValue: dropdownSelectableValuePermissionLevel,
    onChange: onChangeDropdownPermissionLevel,
  } = useDocumentPermissionDropdownField(document.permissionLevel || "all");
  const {
    permittedUsers,
    onChangePermittedUsers,
    updatePermissions,
    onChangePermissions,
  } = useDocumentPermittedUsers(document.id);

  const { filteredUsers, keyword, onChangeKeyword } = useFetchUsers();

  const { sharedDocument, fetchSharedDocument, deleteSharedDocument } =
    useSharedDocument(document.id);

  const {
    isChecked: isOpenMenu,
    setTrue: onOpenMenu,
    setFalse: onCloseMenu,
  } = useBoolean(false);

  const {
    isChecked: isOpenPublicLinkManager,
    setTrue: onOpenPublicLinkManager,
    setFalse: onClosePublicLinkManager,
  } = useBoolean(false);

  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);
  const { userPermission } = useDocumentWithPermission(document.id, {
    onlyPermissionCheck: true,
  });

  const handleUpdatePermission = async () => {
    if (!dropdownValuePermissionLevel) {
      toast.error("アクセス制限を選択してください");
      return;
    }
    setRequestingTrue();
    if (userPermission !== "editor") {
      toast.error("編集者権限がありません");
      return;
    }
    try {
      await updatePermissions(
        dropdownValuePermissionLevel.value as DocumentPermissionType,
        permittedUsers,
      );
      toast.success("共有設定を更新しました");
      refetch();
      onClose();
    } catch (_err) {
      toast.error("エラーです");
    } finally {
      setRequestingFalse();
    }
  };

  const handleDeleteSharedDocument = async () => {
    try {
      await deleteSharedDocument();
      toast.success("リンクを無効化しました");
      fetchSharedDocument();
    } catch (_err) {
      toast.error("リンクの無効化に失敗しました");
    }
  };
  const removeDocumentCreator = useCallback(() => {
    return filteredUsers.filter((user) => user.id !== document.user?.id);
  }, [filteredUsers, document.user?.id]);

  return (
    <ModalWrapper
      onClose={onClose}
      className={`${className}`}
      headerTitle={`${document.systemName}を共有する`}
    >
      <form className="mt-6 space-y-4 flex flex-col justify-between">
        <LabelWithDropdownField
          labelText="アクセス制限"
          value={dropdownValuePermissionLevel}
          options={dropdownSelectableValuePermissionLevel}
          onChange={onChangeDropdownPermissionLevel}
          required
          name={"permission"}
          disabled={userPermission !== "editor"}
        />
        {dropdownValuePermissionLevel?.value === "specific" &&
          userPermission === "editor" && (
            <div>
              <Label
                htmlFor="permittedUsers"
                labelText="ユーザーを追加"
                className="block"
                required={false}
              />
              <button
                className="w-full text-start mt-1.5"
                type="button"
                onClick={onOpenMenu}
              >
                <DropdownField
                  placeholder="選択する"
                  name={"permission"}
                  options={[]}
                  onChange={() => 0}
                />
              </button>
            </div>
          )}

        {isOpenMenu && (
          <FloatingMenu
            className="w-[80%] max-h-[20rem] overflow-y-auto"
            onClose={onCloseMenu}
          >
            <TextFieldWithIcon
              icon={"aiOutlineSearch"}
              type={"search"}
              name={"users"}
              className="my-2"
              value={keyword}
              onChange={onChangeKeyword}
            />
            <SelectTenantUserMenuList
              users={removeDocumentCreator()}
              permittedUsers={permittedUsers}
              onChangePermission={onChangePermittedUsers}
            />
          </FloatingMenu>
        )}
        <Label
          htmlFor="permission"
          labelText="アクセスできるユーザー"
          className="block"
          required={false}
        />
        <div className="flex flex-col max-h-[40vh] justify-between">
          {dropdownValuePermissionLevel?.value === "all" ? (
            <SharedAllUserList usersList={filteredUsers} />
          ) : (
            <SharedSpecificUserList
              permittedUsers={permittedUsers}
              document={document}
              disabled={userPermission !== "editor"}
              onChangePermissions={onChangePermissions}
              className="min-h-[12rem] overflow-y-auto"
            />
          )}
        </div>
        {userPermission === "editor" && (
          <PublicLinkManager
            onDeleteSharedDocument={handleDeleteSharedDocument}
            sharedDocument={sharedDocument}
            onOpen={onOpenPublicLinkManager}
            className="py-4 border-y border-blueGray-50"
          />
        )}
        <div className="space-x-4 text-right">
          <Button
            text={"キャンセル"}
            color={"gray"}
            variant={"contained"}
            onClick={onClose}
          />
          <Button
            text="保存"
            color="primary"
            variant="contained"
            className="text-sm"
            disabled={userPermission !== "editor" || isRequesting}
            onClick={handleUpdatePermission}
          />
        </div>
      </form>
      {isOpenPublicLinkManager && (
        <PublicLinkEnableModal
          onClose={onClosePublicLinkManager}
          document={document}
          refetch={fetchSharedDocument}
          className="w-full h-full"
        />
      )}
    </ModalWrapper>
  );
};
