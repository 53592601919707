import React from "react";

import { DropdownField, SingleValueType } from "@spesill/components/atoms";

import { useUserPermissionDropdownField } from "@spesill/hooks";
import {
  UserPermissionType,
  UserWithPermission,
} from "@spesill/models/permittedUser";

type PropsType = {
  user: UserWithPermission;
  disabled?: boolean;
  onChangePermissions: (userId: string, permission: UserPermissionType) => void;
};

export const UserPermissionDropdownField: React.FC<PropsType> = ({
  user,
  disabled = true,
  onChangePermissions,
}) => {
  const { dropdownSelectableValue, dropdownValue, onChange } =
    useUserPermissionDropdownField(user.permission || "viewer");

  const handleChangePermission = (newValue: SingleValueType) => {
    if (!newValue) return;
    onChange(newValue);
    onChangePermissions(user.id, newValue.value as UserPermissionType);
  };

  return (
    <DropdownField
      value={dropdownValue}
      name="permission"
      required
      isClearable={false}
      disabled={disabled}
      options={dropdownSelectableValue}
      onChange={(e) => handleChangePermission(e)}
    />
  );
};
